<template>
  <div class="stub">
    <template v-if="earlyStub === earlyGeneralSessions">
      <h2>
        General Sessions<br />
        {{ earlyGeneralSessions ? '10:00 a.m. &ndash; Noon' : '2:15 &ndash; 4:15 p.m.'}}
      </h2>
      <h2 :style="`color: ${ earlyGeneralSessions ? '#cf1f25' : '#000' }`">
        {{ earlyGeneralSessions ? 'Red' : 'White'}} Lanyard<br />
        <small style="color: #000">(No tickets required.)</small>
      </h2>
    </template>
    <template v-else>
      <h2>
        Focus Sessions<br />
        {{ earlyGeneralSessions ? '2:00 and 3:30 p.m.' : '10:00 and 11:15 a.m.'}}
      </h2>
      <h2 :style="`color: ${ earlyGeneralSessions ? '#cf1f25' : '#000' }`">
        {{ earlyGeneralSessions ? 'Red' : 'White'}} Lanyard<br />
        <small style="color: #000">(No tickets required.)</small>
      </h2>
    </template>
  </div>
</template>

<script>
export default {
  name: 'SessionStub',
  props: {
    earlyGeneralSessions: Boolean,
    earlyStub: Boolean,
  }
}
</script>

<style scoped>
.stub {
  width: 2.5in;
  height: 1.75in;
  margin: 0.21in 0.125in;
  position: inherit;
}
h2 small {
  font-size: 0.em;
}
</style>