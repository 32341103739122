<template>
  <div class="super-stub">
    <h1 class="legal-name">{{ legalNameAlpha }}</h1>
    <p class="email-truncated">{{emailTruncated}}</p>
    <barcode class="barcode" :text="'R-' + registration.id" />
    <ul class="entitlements">
      <li v-if="hasGiftShopOrder">Gift Shop Order</li>
      <li v-if="hasEarly">Early Sticker</li>
      <li v-if="hasAllAccess">All Access Sticker</li>
    </ul>
    <h2 class="lanyard" :style="`color: ${ registration.earlyGeneralSessions ? '#cf1f25' : '#000' }`">{{ registration.earlyGeneralSessions ? 'Red' : 'White'}}<br />Lanyard</h2>
    <h2 class="checkmark">{{ hasCheckmark ? '&#9746;' : '&#9744;' }}</h2>
  </div>
</template>

<script>
import { some as _some } from 'lodash';
import Barcode from './Barcode';

export default {
  name: 'SuperStub',
  components: { Barcode },
  props: {
    registration: Object
  },
  computed: {
    legalNameAlpha() {
      const { nameLast, nameFirst } = this.registration.profile;
      if( nameLast === null ) {
        return nameFirst;
      }
      return [nameLast, nameFirst].join(', ');
    },
    hasCheckmark() {
      return _some(this.registration.tags, { slug: 'envelope' })
    },
    hasGiftShopOrder() {
      return _some(this.registration.tags, { slug: 'gift-shop-order' })
    },
    hasEarly() {
      return _some(this.registration.tags, { slug: 'early' })
    },
    hasAllAccess() {
      return _some(this.registration.tags, { slug: 'all-access' })
    },
    emailTruncated() {
      if( this.registration.profile.email === null || this.registration.profile.email === '') {
        return;
      }
      const parts = this.registration.profile.email.split('@');

      return parts[0].substring(0,3) + '...@' + parts[1].substring(0,3) + '...';
    }
  }
}
</script>

<style scoped>
.super-stub {
  width: 2.25in;
  height: 3in;
  margin: 0.125in;
  position: inherit;
  text-align: left;
  padding: 0.125in;
}
h1.legal-name {
  margin-top: 0;
  margin-bottom: 0;
  transform: rotate(180deg);
}
p.email-truncated {
  margin-top: 0;
  transform: rotate(180deg);
}
h2.checkmark {
  font-size: 50pt;
  line-height: 46pt;
  display: block;
  position: absolute;
  bottom: 16px;
  right: 0;
  margin: 0;
  padding: 0;
}
h2.lanyard {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  padding-left: 0.125in;
}
ul.entitlements li {
  /* list-style-type: square; */
  padding-bottom: 0.5em;
}
.barcode {
  width: 2.25in;
  height: 0.25in;

}
</style>