<template>
  <div class="stub">
    <logo :color="color" />
  </div>
</template>

<script>
import Logo from './Logo.vue'
export default {
  components: { Logo },
  name: 'EmptyStub',
  props: {
    color: String
  }
}
</script>

<style scoped>
.stub {
  width: 2.5in;
  height: 1.2715in;
  margin: 0.125in;
  position: inherit;
  padding-top: 0.4785in;
}
</style>