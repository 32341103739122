<template>
  <div class="stub">
    <h1>The Grand Prize Giveaway!</h1>
    <h2>Sunday, August 4, 2024<br />Winners need not be present.</h2>
    <p class="name"><v-runtime-template :template="`<strong>${badgeNameFull}</strong>`" /></p>
  </div>
</template>

<script>
import VRuntimeTemplate from 'v-runtime-template';

export default {
  name: 'RaffleStub',
  components: { VRuntimeTemplate },
  props: {
    badgeNameFull: String,
  }
}
</script>

<style scoped>
.stub {
  width: 2.25in;
  height: 1.75in;
  margin: 0.125in 0.25in 0in;

  position: inherit;
  font-size: 10pt;

  box-sizing: border-box;
}
p {
  margin: 8px;
  font-size: 12pt;
  line-height: 12pt;
}
p.name {
  background-color: #DFDFDF;
  padding: 10px;
  margin: 0;
  font-family: 'Alegraya Sans Bold';
  font-size: 14pt;
}
h1 {
  margin-top: 0;
  margin-bottom: 0;
  line-height: 20pt;
}
h2 {
  font-weight: normal;
  margin-top: 4px;
}
</style>