<template>
  <div>
    <div id="page" v-if="registration">
      <div id="main">
        <badge :registration="registration"></badge>
        <ticket v-for="ticket in registration.tickets" :key="ticket.id" :ticket="ticket" :badgeNameFull="registration.profile.badgeNameFull" :boardingGroups="boardingGroups"></ticket>
        <template v-if="registration.tickets.length < 5">
        <empty-ticket v-for="index in 5 - registration.tickets.length" :key="index" />
        </template>
      </div>
      <div id="sidebar">
        <super-stub :registration="registration"></super-stub>
        <template v-if="registration.sessions">
          <session-stub :earlyGeneralSessions="registration.earlyGeneralSessions" :earlyStub="true" />
          <session-stub :earlyGeneralSessions="registration.earlyGeneralSessions" :earlyStub="false" />
        </template>
        <program-stub v-if="!partialType" :badgeNameFull="registration.profile.badgeNameFull" :registrationId="registration.id" />
        <empty-stub v-for="index in emptyStubCount" :key="index" :color="typeof registration.tickets[(4 - emptyStubCount) + (index - 1)] === 'undefined' ? '#999' : registration.tickets[(4 - emptyStubCount) + (index - 1)].capacitySegment.showtime.ticketColor" /> <!-- #cf2127 -->
        <raffle-stub v-if="!partialType" :badgeNameFull="registration.profile.badgeNameFull" />
      </div>
    </div>

    <div id="guides">
      <hr style="top: 0.25in;" />
      <hr style="top: 3.75in;" />
      <hr style="top: 5.75in;" />
      <hr style="top: 7.75in;" />
      <hr style="top: 9.75in;" />
      <hr style="top: 11.75in;" />
      <hr style="top: 13.75in;" />
      <hr class="vr" style="left: 0.5in;" />
      <hr class="vr" style="left: 5.5in;" />
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { find as _find, remove as _remove, some as _some, sortBy as _sortBy } from 'lodash';


import Badge from '../components/Badge';
import EmptyStub from '../components/EmptyStub.vue';
import SuperStub from '../components/SuperStub';
import Ticket from '../components/Ticket.vue';
import ProgramStub from '../components/ProgramStub.vue';
import SessionStub from '../components/SessionStub.vue';
import EmptyTicket from '../components/EmptyTicket.vue';
import RaffleStub from '../components/RaffleStub.vue';

export default {
  name: 'Registrations',
  components: { Badge, EmptyStub, SuperStub, Ticket, ProgramStub, SessionStub, EmptyTicket, RaffleStub },
  props: {
    globals: Object,
  },
  data() {
    return {
      registration: false,
    }
  },
  methods: {
    async loadHandler() {
      const request = {
        url: this.globals.endpoint + '/v3/registrations/' + this.$route.query.id,
        params: {
          $eager: '[group,profile,tickets.capacitySegment.showtime.show,tags]',
        }
      }

      const result = await axios(request).catch(error => {
        console.log(error);
      })

      this.registration = result.data;

      // Strip out the General Session ticket
      const sessionArray = _remove(this.registration.tickets, { showId: 14});

      // Capacity segment 78 is early GS, 79 is late
      this.registration.sessions = sessionArray.length === 1;
      if(!this.registration.sessions) return;

      this.registration.earlyGeneralSessions = sessionArray[0].capacitySegment.id === 78;

      // Sort tickets
      this.registration.tickets = _sortBy(this.registration.tickets, 'capacitySegment.showtime.startTime')

    },
  },
  computed: {
    partialType() {
      // If attendee, return false
      if( _some(this.registration.tags, { slug: 'attendee' }) ) {
        return false;
      }
      
      const { name: partialType } = _find(this.registration.tags, (o) => {
        return ['dealer', 'parties', 'monday', 'tuesday', 'wednesday'].includes(o.slug);
      }) || false;
      
      return partialType;
    },
    emptyStubCount() {
      return 1 + ( this.partialType ? 1 : 0 ) + ( this.registration.sessions ? 0 : 2 );
    },
    boardingGroups() {
      // const { slug: mondayBoardingGroup } = _find(this.registration.tags, (o) => {
      //   return ['stage-monday-a', 'stage-monday-b'].includes(o.slug);
      // }) || 'stage-monday-c';

      const { slug: wednesdayBoardingGroup } = _find(this.registration.tags, (o) => {
        return ['stage-wednesday-a', 'stage-wednesday-b'].includes(o.slug);
      }) || 'stage-wednesday-c';

      return {
        // monday: mondayBoardingGroup === 'stage-monday-a' ? 'A' : mondayBoardingGroup === 'stage-monday-b' ? 'B' : 'C',
        wednesday: wednesdayBoardingGroup === 'stage-wednesday-a' ? 'A' : wednesdayBoardingGroup === 'stage-wednesday-b' ? 'B' : 'C',
      };
    }
  },
  created() {
    this.loadHandler();
  }
}
</script>

<style>

#page {
  position: absolute;
  top: 0.25in;
  left: 0.5in;
}
#main {
  position: inherit;
}
#sidebar {
  position: inherit;
  left: 5in;
}

.badge {
  transform: rotate(90deg) translate(0, -100%);
	transform-origin: top left;
  position: inherit;
  top: 0;
  left: 0;
}

.super-stub {
  top: 0;
}

.ticket:nth-of-type(2), .stub:nth-of-type(2) {
  top: 3.5in;
}
.ticket:nth-of-type(3), .stub:nth-of-type(3) {
  top: 5.5in;
}
.ticket:nth-of-type(4), .stub:nth-of-type(4) {
  top: 7.5in;
}
.ticket:nth-of-type(5), .stub:nth-of-type(5) {
  top: 9.5in;
}
.ticket:nth-of-type(6), .stub:nth-of-type(6) {
  top: 11.5in;
}

/* START: GUIDES */
@media print
{    
    #guides
    {
        display: none !important;
    }
}

#guides {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
hr {
  position: absolute;
  width: 8.5in;
  height: 0;
  border-top: 4px dashed orange;
  border-bottom: none;
  margin: -2px 0 0 0;
  padding: 0;
}

hr.vr {
  width: 0;
  height: 14in;
  border: none;
  border-left: 4px dashed orange;
  margin: 0 0 0 -2px;
}
/* END: GUIDES */
</style>