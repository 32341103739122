<template>
  <div class="ticket">
    <logo color="#999" />
  </div>
</template>

<script>
import Logo from './Logo.vue';

export default {
  name: 'EmptyTicket',
  components: { Logo },
}
</script>

<style scoped>
.ticket {
  width: 4.75in;
  height: 1.2715in;
  margin: 0.125in;
  position: inherit;
  padding-top: 0.4785in;
}
</style>