<template>
  <div class="stub">
    <div class="stub-body">
      <p>Redeem this ticket for your</p>
      <p class="title"><strong>2024 Souvenir Program</strong></p>
      <p>at the Farewell Party on<br />
      Wednesday at 10:00 p.m.</p>
      <p><v-runtime-template :template="`<strong>${badgeNameFull}</strong>`" /></p>
    </div>
    <barcode class="barcode" :text="'P-' + registrationId" />
  </div>
</template>

<script>
import VRuntimeTemplate from 'v-runtime-template';
import Barcode from './Barcode';

export default {
  name: 'ProgramStub',
  components: { VRuntimeTemplate, Barcode },
  props: {
    badgeNameFull: String,
    registrationId: Number
  }
}
</script>

<style scoped>
.stub {
  width: 2.25in;
  height: 1.75in;
  margin: 0.125in 0.25in 0in;

  position: inherit;
  font-size: 10pt;

  box-sizing: border-box;
  /* border-radius: 0.25in; */
}
.stub-body {
  padding: 5px 0;
  border: 0.03125in solid #cf1f25;
}
strong {
  color: #cf1f25;
  font-family: 'Alegraya Sans Bold';
  font-size: 14pt;
}
p {
  margin: 8px;
  font-size: 12pt;
  line-height: 12pt;
}
.barcode {
  width: 2.25in;
  height: 0.25in;
  position: absolute;
  bottom: 0;
  transform-origin: top left;
}
</style>